import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "blog & procrastination",
  "author": "Rohan",
  "date": "2020-12-19T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hey Everyone , This is the first post i will be posting on this site.`}</p>
    <p>{`I would have started this a long ago but i always Procrastinated things saying that "i make great blog with great features and styling".
But the thing is a blog a with great styling but no content is of no use.
So , i started making this blog with basic Figma designs i made and thought lets start and atleast make something where people can read some content.`}</p>
    <p><inlineCode parentName="p">{`So please avoid the coding mistakes & other ones as well because i have very hardly manage time from my full time work and life to get this done is very short time,make sure to make PR on the github repo of this site to improve , i would really appriciate that.`}</inlineCode></p>
    <p><inlineCode parentName="p">{`I will keep improving the experience slowly `}</inlineCode></p>
    <h4>{`Why this blog ?`}</h4>
    <p>{`I always had a passion to teach people whatever i learn by briging some great creative methodologies.
So i started my Youtube Channel.`}</p>
    <p>{`I wanted to document it somewhere so started this blog.`}</p>
    <h4>{`For whom this blog is for ?`}</h4>
    <p>{`People who like to code and build things are welcome to read this.
Also i will sharing some life stories & youtube stuffs so bookmark and get ready because great experiments will be coming here.`}</p>
    <h4>{`I want your support ?`}</h4>
    <p>{`Whoever reading this, this is all because of you.
Thanks for believing me and supporting me.`}</p>
    <p>{`I also have a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/rohanprasadofficial"
      }}>{`Youtube`}</a>{`
Channel. Do subscribe to support me.`}</p>
    <p>{`Also subscribe my monthly newsletter where i will post some great links & things i tried every month.`}</p>
    <h4>{`General Idea`}</h4>
    <p>{`Teaching is learning.`}</p>
    <p>{`I have been learning great things alot @Microsoft where i work as of now. I have great mentors and surrounded by great minds.
So i will bring so learnings to my channel & to this site.`}</p>
    <p>{`I will catch up in next post`}</p>
    <p><inlineCode parentName="p">{`Ro.`}</inlineCode></p>
    <h1></h1>
    <h1></h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      